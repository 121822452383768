.elem-breadcramps {
    padding: 30px 0;
    &__list {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__item {
        position: relative;
        &+& {
            margin-left: 20px;
        }
        &:first-child {
            &:before {
                content: none;
            }
        }
        &:before {
            position: absolute;
            content: '-';
            color: #a2a2a2;
            top: 1px;
            left: -12px;
        }
    }
    &__link {
        font-size: 14px;
        color: #a2a2a2;
        font-family: 'CorporateS';
        white-space: nowrap;
        // font-weight: 300;
    }
}
.elem-title {
    font-family: 'CorporateAC';
    font-weight: 300;
    text-transform: none;
    font-size: 31px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 27px;
    }

    &--lg {
      font-size: 35px;
      margin-bottom: 40px;
      max-height: 48px;
    }

    &--sm {
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 42px;
    }

    &--center {
      justify-content: center;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        margin-bottom: 28px;

        @media (max-width: 768px) {
          display: block;
          margin-bottom: 18px;
        }
    }

    &__img {
      margin-right: 26px;
    }
}

.block-question {
    background: #fff;
    &__wrapper {
        max-width: 1143px;
        margin: 0 auto;
        padding: 50px 20px;
        margin-bottom: 40px;
        @media (max-width: 768px) {
            padding: 20px 20px;
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        color: #000;
        border-bottom: 1px solid #cccccc;
        margin-bottom: 35px;
    }
    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
    &__name {
        font-family: 'CorporateS';
        font-weight: 700;
        font-size: 19px;
    }
    &__date {
        font-family: 'CorporateS';
        font-size: 19px;
        color: #919191;
        margin-left: 10px;
    }
    &__text {
        font-family: 'CorporateS';
        font-size: 19px;
        margin-bottom: 34px;
        @media (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
    &__answer {
        font-family: 'CorporateS';
        font-size: 19px;
        max-width: 1085px;
        background: #ebebeb;
        padding: 30px;
        position: relative;
        margin-left: 63px;
        margin-bottom: 28px;
        @media (max-width: 768px) {
            margin-left: 0;
        }
        &:before {
            content: '';
            position: absolute;
            top: -15px;
            left: 0;
            border: 15px solid transparent;
            border-left: 30px solid #ebebeb;
            @media (max-width: 768px) {
                content: none;
            }
        }
    }
}
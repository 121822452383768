.visuallyhidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

@media (max-width: 768px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 769px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: $sm) {
    .hidden-tab {
        display: none !important;
    }
}

@media (min-width: $sm) and (max-width: calc($dsk - 1px)) {
    .hidden-only-tab {
        display: none !important;
    }
}

@media (max-width: calc($dsk - 1px)) {
    .hidden-mob {
        display: none !important;
    }
}

@media (min-width: $dsk) {
    .hidden-dsk {
        display: none !important;
    }
}

@media (min-width: $lg) {
    .hidden-lg {
        display: none !important;
    }
}
.menu-footer {
    background: #1b1b1b;
    padding: 6px 0 0;

    &__list {
        margin: 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px) {
          flex-wrap: wrap;
          margin: 0;
          flex-direction: column;
          height: 110px;
          justify-content: flex-start;
          align-items: flex-start;
        }
    }

    &__item {
        padding: 30px;

        @media (max-width: 768px) {
          padding: 0;
          width: 50%;
          margin-bottom: 7px;
        }
    }

    &__link {
        color: #fff;
        text-decoration: none;
        font-size: 18px;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
          display: block;
        }
    }
}

@font-face {
    font-family: "CorporateS";
    src: url("../fonts/CorporateS-Regular.woff2") format("woff2"), url("../fonts/CorporateS-Regular.woff") format("woff");
}

@font-face {
    font-family: "CorporateS";
    src: url("../fonts/CorporateSLight.woff2") format("woff2"), url("../fonts/CorporateS-Light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "CorporateS";
    src: url("../fonts/CorporateSBold.woff2") format("woff2"), url("../fonts/CorporateS-Bold.woff") format("woff");
    font-weight: 700;
}

@font-face {
    font-family: "CorporateA";
    src: url("../fonts/CorporateA-Light.woff2") format("woff2"), url("../fonts/CorporateA-Light.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "CorporateAC";
    src: url("../fonts/CorporateACon-Reg.woff2") format("woff2"), url("../fonts/CorporateACon-Reg.woff") format("woff");
    font-weight: 300;
}

@font-face {
    font-family: "lg";
    src: url("../fonts/lg.woff2") format("woff2"), url("../fonts/lg.woff.woff") format("woff");
    font-weight: 300;
}
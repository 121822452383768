.form-search {
  position: relative;

  &__btn {
    font-size: 16px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0, -40%);
  }
}

.box-gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    margin-bottom: 40px;
    &__item {
        margin: 0 4px 8px;
        height: 166px;
        flex: 1 1 auto;
        max-width: 338px;
        &:nth-child(n + 7) {
            height: 178px;
        }
        @media (max-width: 1689px) {
            &:nth-child(n + 6) {
                height: 178px;
            }
        }
    }
    &__link {
        display: block;
        cursor: pointer;
        border: 1px solid rgba(#363a3c, 0.5);
        height: 100%;
    }
    &__img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}
html {
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-size-adjust: 100%;
    text-size-adjust: 100%;
    touch-action: manipulation;
}

body {
    background-color: #000000;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: 'CorporateS';
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 500;
    min-height: 100%;
    &.no-scroll {
        overflow: hidden;
    }
}

// IE 10-11
.header,
.footer {
    flex-shrink: 0;
}

[type='search'],
[type='text'],
[type='passowrd'],
[type='tel'] {
    &::-ms-clear {
        display: none;
    }
}

.page-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
}

main {
    flex: 1 0 auto;
}

body * {
    box-sizing: border-box;
}

img {
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

video {
    height: 100%;
    max-width: 100%;
    object-fit: inherit;
}

a {
    text-decoration: none;
    transition: 0.3s;
}

h1,
h2,
h3 {
    font-weight: 600;
    margin-top: 0;
    text-transform: uppercase;
}

h1,
.h1 {}

h2,
.h2 {}

h3,
.h3 {}

h4,
.h4 {}

h5,
.h5 {}

h6,
.h6 {}

.icon {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    margin: 0;
    width: 1em;
}

// Reset for one view
button,
.btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: inherit;
    font-weight: inherit;
    padding: 0;
    text-align: center;
    transition: 0.3s;
    user-select: none;
    &:disabled,
    &[disabled='disabled'] {
        cursor: not-allowed;
    }
}

input {
    &::placeholder {
        color: $secondary;
    }
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.of-contain {
    font-family: 'object-fit: contain' !important;
}

.of-cover {
    font-family: 'object-fit: cover' !important;
}

.wrapper {
    max-width: 1689px;
    margin: 0 auto;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      max-width: 100%;
    }
}

input {
    max-width: 329px;
    padding: 8px 10px 9px;
    margin-left: 15px;
}

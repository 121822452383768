.elem-banner {
  &__img {
    @media (max-width: 768px) {
      height: 100px;
      display: flex;
      justify-content: center;
    }

    & img {
      height: 100%;
      max-width: none;
      min-width: 100%;
      object-fit: cover;
      object-position: top;
    }
  }
}

.block-img {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: 480px) {
    margin: 0;
  }

  &__item {
    padding: 0 15px;
    width: 100% / 6;

    @media (max-width: 768px) {

      margin-bottom: 15px;
      width: 100% / 3;
    }

    @media (max-width: 480px) {
      width: 100%;
      padding: 0;
    }
  }

  &__img {
    width: 100%;
  }
}

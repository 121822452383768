/*=== Helpers ====*/

@import 'helpers/mixins';
@import 'helpers/fonts';
@import 'normalize.css';
@import 'helpers/utilities';

/*=== Plugins ====*/


/* an example of how to import from node_modules
  @import "select2";
*/


/* Customize styles */

@import 'plugins/*';
@import '../../node_modules/slick-carousel/slick/slick.scss';
// @import '../../node_modules/lightgallery/src/sass/lightgallery.scss';
// @import '../../node_modules/lightgallery/src/sass/lg-transitions.scss';

/*=== All ====*/

@import 'base';
@import 'btn/*';
@import 'elem/*';
@import 'block/*';
@import 'nav/*';
@import 'slider/*';
@import 'section/*';
@import 'card/*';
@import 'box/*';
@import 'common/*';
@import 'components/*';
@import 'form/*';
@import 'table/*';
@import 'popup/*';
@import 'page/*';

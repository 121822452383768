.main-menu {
    background: #363636;

    @media (max-width: 768px) {
      right: -100vw;
      position: absolute;
      top: 60px;
      background: #ffffff;
      transition: all 0.3s ease-in-out;
      width: 282px;
      z-index: 999;
    }

    &.open {
      right: 0;
    }

    &__list {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media (max-width: 768px) {
          display: block;
          margin-left: -15px;
          margin-right: -15px;
        }
    }

    &__item {
        display: flex;
        position: relative;

        @media (max-width: 768px) {
          display: block;
        }

        &.opened {
            &:hover {
                .main-submenu {

                    @media (min-width: 769px) {
                      opacity: 1;
                      display: flex;

                    }
                }
            }

            & .main-submenu {
              @media (max-width: 768px) {
                display: block;
                opacity: 1;
              }
            }
        }
    }

    &__link {
        color: #fff;
        font-size: 18px;
        padding: 21px 21px;
        position: relative;

        @media (max-width: 768px) {
          font-size: 18px;
          line-height: 22px;
          color: #000000;
          padding: 9px 20px 10px;
          border-bottom: 1px solid #dadada;
          width: 100%;
          display: block;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 9px;
            left: 50%;
            transform: translate(-50%, 0);
            background-image: url("/assets/images/example/arrow.png");
            background-repeat: no-repeat;
            width: 11px;
            height: 7px;
            background-size: contain;

            @media (max-width: 768px) {
              background-image: url("/assets/images/example/arrow2.png");
              left: auto;
              transform: translate(0, -50%);
              bottom: auto;
              top: 50%;
              width: 7px;
              height: 9px;
              right: 15px;
            }
        }

        &:hover {
            background: #1b1c20;

            @media (max-width: 768px) {
              background: transparent;
            }
        }

        &.active {
            background: #1b1c20;

            @media (max-width: 768px) {
              background: transparent;
            }
        }
    }

    &__item.opened &__link {
      @media (max-width: 768px) {
        border-color: transparent;
      }
    }
}

.main-submenu {
    flex-shrink: 0;
    position: absolute;
    top: 62px;
    left: 0;
    display: flex;
    justify-content: center;
    opacity: 0;
    display: none;
    transition: all .2s ease-in;

    @media (max-width: 768px) {
      display: none;
      position: relative;
      top: auto;
      left: auto;
      border-bottom: 1px solid #dadada;
      padding-bottom: 7px;
      margin-top: -10px;
    }

    &__item {
        width: 170px;
        flex-shrink: 1;

        @media (max-width: 768px) {
          width: 100%;
        }

        & + & {
            margin-left: 1px;

            @media (max-width: 768px) {
              margin-left: 0;
            }
        }
    }

    &__link {
        display: flex;
        flex-shrink: 1;
        flex-direction: column;
        width: 170px;
        height: 209px;
        background: #1b1c20;

        @media (max-width: 768px) {
          width: 100%;
          height: auto;
          display: block;
          background: transparent;
          margin-bottom: 5px;
        }
    }

    &__img {
        width: 100%;

        @media (max-width: 768px) {
          display: none;
        }

        img {
            width: 170px;
        }
    }

    &__text {
        color: #fff;
        font-size: 18px;
        line-height: 20px;
        padding: 9px 15px;
        font-family: 'CorporateS';
        height: 100%;
        display: flex;
        align-items: center;

        @media (max-width: 768px) {
          color: #505050;
          font-size: 16px;
          line-height: 20px;
          padding: 5px 20px 0 40px;
          position: relative;
        }

        &:before {
          @media (max-width: 768px) {
            content: '-';
            position: absolute;
            left: 32px;
            top: 5px;
          }
        }
    }
}

.block-info {
    padding-top: 40px;
    padding-bottom: 47px;

    @media (max-width: 768px) {
      padding-top: 28px;
      padding-bottom: 0;
    }

    &__row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          display: block;
        }
    }

    &__text {
        width: 50% - 20px;
        font-size: 20px;
        opacity: 0.85;

        @media (max-width: 768px) {
          width: 100%;
          font-size: 17px;
          line-height: 24px;
        }

        & p {
          @media (max-width: 768px) {
            margin-top: 0;
            margin-bottom: 17px;
          }
        }
    }

    &__img {
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    & .elem-title {
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }
    }
}

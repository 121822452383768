.block-articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-template-areas: "div1 div1 div2 div3" "div1 div1 div4 div5" "div6 div7 div8 div9" "div10 div11 div12 div13";
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-bottom: 52px;
    @media (max-width: 768px) {
        display: block;
    }
    &__info {
        @media (max-width: 768px) {
            margin-bottom: 20px;
        }
    }
    &__item {
        color: #ffffff;
        transition: opacity 0.4s ease-in-out;
        &.div1 {
            grid-area: div1;
        }
        &.div2 {
            grid-area: div2;
        }
        &.div3 {
            grid-area: div3;
        }
        &.div4 {
            grid-area: div4;
        }
        &.div5 {
            grid-area: div5;
        }
        &.div6 {
            grid-area: div6;
        }
        &.div7 {
            grid-area: div7;
        }
        &.div8 {
            grid-area: div8;
        }
        &.div9 {
            grid-area: div9;
        }
        &.div10 {
            grid-area: div10;
        }
        &.div11 {
            grid-area: div11;
        }
        &.div12 {
            grid-area: div12;
        }
        &.div13 {
            grid-area: div13;
        }
        &:hover {
            opacity: 0.5;
        }
    }
    &__img {
        margin-bottom: 13px;
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        font-size: 24px;
        line-height: 27px;
        font-weight: 400;
        margin-bottom: 12px;
    }
    &__text {
        font-size: 18px;
        line-height: 20px;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
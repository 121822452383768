.block-inner {
    background: #fff;
    padding: 33px 48px 48px;
    color: #000;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 24px;
    @media (max-width: 768px) {
        padding: 20px;
    }
    &__row {
        overflow: hidden;
    }
    &__img {
        float: left;
        margin-right: 45px;
        margin-bottom: 49px;
    }
    & p {
        margin-bottom: 33px;
        margin-top: 14px;
    }
}
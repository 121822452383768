.elem-logos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    &__img {
        img {
            width: 100%;
        }
    }
}
.footer {
    position: relative;

    &__bottom {
        background: #363636;
        padding: 40px 0;

        @media (max-width: 768px) {
          padding: 15px 0;
        }
    }

    & .header-logo__img img {
      @media (max-width: 768px) {
        width: 104px;
      }
    }

    &__row {
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          justify-content: flex-start;
          flex-direction: column;
        }
    }

    &__col {
        flex-basis: 50%;

        &:first-child {
          @media (max-width: 768px) {
            text-align: center;
            order: 1;
          }
        }

        &:nth-child(2) {
            text-align: center;
            align-self: flex-end;

            @media (max-width: 768px) {
              order: 3;
              align-self: center;
            }
        }

        &:nth-child(3) {
            text-align: center;
            align-self: flex-end;

            @media (max-width: 768px) {
              order: 4;
              align-self: center;
            }
        }

        &:last-child {
            align-self: flex-end;

            @media (max-width: 768px) {
              order: 2;
              align-self: center;
            }
        }

        a {
            font-size: 18px;
            color: #fff;

            @media (max-width: 768px) {
              font-size: 14px;
            }
        }
    }

    &__contacts {
        text-align: right;
        align-self: flex-end;

        @media (max-width: 768px) {
          align-self: center;
          text-align: center;
          padding-top: 7px;
          padding-bottom: 11px;
        }

        p {

          @media (max-width: 768px) {
            margin: 0;
          }

            &:first-child {
                font-size: 19px;

                @media (max-width: 768px) {
                  font-size: 16px;
                  margin-bottom: 8px;
                }
            }

            &:nth-child(2) {
                font-size: 18px;
                margin-bottom: 10px;

                @media (max-width: 768px) {
                  font-size: 16px;
                  margin-bottom: 3px;
                }
            }

            &:last-child {
                margin-bottom: 0;
                font-size: 14px;
                margin-top: 0;

                @media (max-width: 768px) {
                  font-size: 16px;
                  margin-left: -5px;
                  margin-right: -5px;
                }
            }
        }
        span {
            opacity: 0.7;
        }
    }

    &__copywrite {
        margin-top: 40px;
        margin-left: 10px;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-top: 10px;
          font-size: 14px;
        }
    }

    &__up {
        display: flex;
        width: 60px;
        height: 60px;
        background: #444444;
        position: absolute;
        top: -66px;
        right: 7%;

        @media (max-width: 768px) {
          height: 40px;
          width: 40px;
          right: 26px;
          top: -28px;
        }

        &:before {
            content: '';
            position: absolute;
            bottom: 23px;
            left: 50%;
            transform: translate(-50%, 0) rotate(180deg);
            background-image: url("/assets/images/example/arrow.png");
            background-repeat: no-repeat;
            width: 17px;
            height: 11px;
            background-size: contain;

            @media (max-width: 768px) {
              height: 8px;
              width: 13px;
              bottom: 17px;
            }
        }
    }
}

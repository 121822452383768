.block-article {
    background: #fff;
    padding: 33px 48px 48px;
    color: #000;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 24px;
    &__wrapper {
        max-width: 960px;
        margin: 0 auto;
    }
    &__social {
        &-img {
            margin-left: 10px;
        }
    }
    &__date {
        font-size: 16px;
        color: rgba(#000, 0.5);
        font-family: 'CorporateS';
    }
    &__more {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        .block-articles__item {
            flex-basis: 25% - 20px;
            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }
}
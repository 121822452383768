.skills-row {
  &__title {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 26px;
    margin-left: 1px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4%;
  }

  &__item {
    margin-left: 4%;
  }
}

.elem-pagination {
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__prev {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            background: #f5f5f5;
            border-radius: 50%;
            position: relative;
            margin-right: 25px;
            &:before {
                position: absolute;
                content: '';
                top: 19px;
                left: 50% - 2px;
                border: solid #b5b5b5;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
            &:hover {
                &:before {
                    border-color: #6c6c6c;
                }
            }
        }
    }
    &__page {
        a {
            font-size: 19px;
            font-family: 'CorporateS';
            font-weight: 300;
            font-size: 19px;
        }
        &+& {
            margin-left: 10px;
        }
        &.active {
            a {
                color: #c9c9c9;
            }
        }
    }
    &__next {
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            background: #f5f5f5;
            border-radius: 50%;
            position: relative;
            margin-left: 25px;
            &:before {
                position: absolute;
                content: '';
                top: 19px;
                left: 50% - 5px;
                border: solid #b5b5b5;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
            &:hover {
                &:before {
                    border-color: #6c6c6c;
                }
            }
        }
    }
}
.block-advantages {
    padding: 40px;
    background: #363636;

    @media (max-width: 768px) {
      padding: 22px 0 14px;
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 90px;

        @media (max-width: 768px) {
          display: block;
          padding: 0;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: row;
          max-width: 100%;
          align-items: stretch;
          margin-bottom: 18px;
        }
    }

    &__img {
        margin-bottom: 30px;

        @media (max-width: 768px) {
          margin-bottom: 0;
          margin-right: 10px;
          flex-shrink: 0;
          width: 84px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & img {
          @media (max-width: 768px) {
            max-width: 63%;
          }
        }
    }

    &__text {
        font-size: 18px;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }
    }

    & .elem-title {
      @media (max-width: 768px) {
        margin-bottom: 22px;
      }
    }
}

$backdrop-opacity: 1 !default;
$lg-toolbar-bg: rgba(0, 0, 0, 0.45) !default;
$lg-border-radius-base: 2px !default;
$lg-theme-highlight: rgb(169, 7, 7) !default;
$lg-theme: #0D0A0A !default;

// basic icon colours
$lg-icon-bg: rgba(0, 0, 0, 0.45) !default;
$lg-icon-color: #999 !default;
$lg-icon-hover-color: #FFF !default;

// counter
$lg-counter-color: #e6e6e6 !default;
$lg-counter-font-size: 16px !default;

// Next prev icons
$lg-next-prev-bg: $lg-icon-bg !default;
$lg-next-prev-color: $lg-icon-color !default;
$lg-next-prev-hover-color: $lg-icon-hover-color !default;

// toolbar icons
$lg-toolbar-icon-color: $lg-icon-color !default;
$lg-toolbar-icon-hover-color: $lg-icon-hover-color !default;

// autoplay progress bar
$lg-progress-bar-bg: #333 !default;
$lg-progress-bar-active-bg: $lg-theme-highlight !default;
$lg-progress-bar-height: 5px !default;

// paths
$lg-path-fonts: '../fonts'!default;
$lg-path-images: '../img'!default;

// Zoom plugin
$zoom-transition-duration: 0.3s !default;

// Sub html - titile
$lg-sub-html-bg: rgba(0, 0, 0, 0.45) !default;
$lg-sub-html-color: #EEE !default;

// thumbnail toggle button
$lg-thumb-toggle-bg: #0D0A0A !default;
$lg-thumb-toggle-color: $lg-icon-color !default;
$lg-thumb-toggle-hover-color: $lg-icon-hover-color !default;
$lg-thumb-bg: #0D0A0A !default;

// z-index
$zindex-outer: 1050 !default;
$zindex-progressbar: 1083 !default;
$zindex-controls: 1080 !default;
$zindex-toolbar: 1082 !default;
$zindex-subhtml: 1080 !default;
$zindex-thumbnail: 1080 !default;
$zindex-pager: 1080 !default;
$zindex-playbutton: 1080 !default;
$zindex-item: 1060 !default;
$zindex-backdrop: 1040 !default;


// Vendor Prefixes
//
// All vendor mixins are deprecated as of v3.2.0 due to the introduction of
// Autoprefixer in our Gruntfile. They will be removed in v4.

// - Animations
// - Backface visibility
// - Box shadow
// - Box sizing
// - Content columns
// - Hyphens
// - Placeholder text
// - Transformations
// - Transitions
// - User Select
// - cursor grab

// Animations
@mixin animation($animation) {
  -webkit-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin animation-name($name) {
  -webkit-animation-name: $name;
  animation-name: $name;
}

@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}

@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
  animation-timing-function: $timing-function;
}

@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}

@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
  animation-iteration-count: $iteration-count;
}

@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}

@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
  animation-fill-mode: $fill-mode;
}

@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
      @content;
  }

  @-moz-keyframes #{$name} {
      @content;
  }

  @-ms-keyframes #{$name} {
      @content;
  }

  @keyframes #{$name} {
      @content;
  }
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`

@mixin backface-visibility($visibility) {
  -webkit-backface-visibility: $visibility;
  -moz-backface-visibility: $visibility;
  backface-visibility: $visibility;
}

// Drop shadows
//
// Note: Deprecated `.box-shadow()` as of v3.1.0 since all of Bootstrap's
// supported browsers that have box shadow capabilities now support it.

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
  box-shadow: $shadow;
}

// Box sizing
@mixin box-sizing($boxmodel) {
  -webkit-box-sizing: $boxmodel;
  -moz-box-sizing: $boxmodel;
  box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
  -webkit-column-count: $column-count;
  -moz-column-count: $column-count;
  column-count: $column-count;
  -webkit-column-gap: $column-gap;
  -moz-column-gap: $column-gap;
  column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
  word-wrap: break-word;
  -webkit-hyphens: $mode;
  -moz-hyphens: $mode;
  -ms-hyphens: $mode; // IE10+
  -o-hyphens: $mode;
  hyphens: $mode;
}

// Transformations
@mixin scale($ratio...) {
  -webkit-transform: scale($ratio);
  -ms-transform: scale($ratio); // IE9 only
  -o-transform: scale($ratio);
  transform: scale($ratio);
}

@mixin scaleX($ratio) {
  -webkit-transform: scaleX($ratio);
  -ms-transform: scaleX($ratio); // IE9 only
  -o-transform: scaleX($ratio);
  transform: scaleX($ratio);
}

@mixin scaleY($ratio) {
  -webkit-transform: scaleY($ratio);
  -ms-transform: scaleY($ratio); // IE9 only
  -o-transform: scaleY($ratio);
  transform: scaleY($ratio);
}

@mixin skew($x, $y) {
  -webkit-transform: skewX($x) skewY($y);
  -ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
  -o-transform: skewX($x) skewY($y);
  transform: skewX($x) skewY($y);
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y); // IE9 only
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
  transform: translate3d($x, $y, $z);
}

@mixin scale3d($x, $y, $z) {
  -webkit-transform: scale3d($x, $y, $z);
  transform: scale3d($x, $y, $z);
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -ms-transform: rotate($degrees); // IE9 only
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin rotateX($degrees) {
  -webkit-transform: rotateX($degrees);
  -ms-transform: rotateX($degrees); // IE9 only
  -o-transform: rotateX($degrees);
  transform: rotateX($degrees);
}

@mixin rotateY($degrees) {
  -webkit-transform: rotateY($degrees);
  -ms-transform: rotateY($degrees); // IE9 only
  -o-transform: rotateY($degrees);
  transform: rotateY($degrees);
}

@mixin perspective($perspective) {
  -webkit-perspective: $perspective;
  -moz-perspective: $perspective;
  perspective: $perspective;
}

@mixin perspective-origin($perspective) {
  -webkit-perspective-origin: $perspective;
  -moz-perspective-origin: $perspective;
  perspective-origin: $perspective;
}

@mixin transform-origin($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin; // IE9 only
  transform-origin: $origin;
}

@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}

// Transitions

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin transition-property($transition-property...) {
  -webkit-transition-property: $transition-property;
  transition-property: $transition-property;
}

@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
  transition-delay: $transition-delay;
}

@mixin transition-duration($transition-duration...) {
  -webkit-transition-duration: $transition-duration;
  transition-duration: $transition-duration;
}

@mixin transition-timing-function($timing-function) {
  -webkit-transition-timing-function: $timing-function;
  transition-timing-function: $timing-function;
}

@mixin transition-transform($transition...) {
  -webkit-transition: -webkit-transform $transition;
  -moz-transition: -moz-transform $transition;
  -o-transition: -o-transform $transition;
  transition: transform $transition;
}

// transition custom

@function prefix($property, $prefixes: webkit moz o ms) {
  $vendor-prefixed-properties: transform background-clip background-size;
  $result: ();

  @each $prefix in $prefixes {
      @if index($vendor-prefixed-properties, $property) {
          $property: -#{$prefix}-#{$property};
      }
      $result: append($result, $property);
  }
  @return $result;
}

@function trans-prefix($transition, $prefix: moz) {
  $prefixed: ();

  @each $trans in $transition {
      $prop-name: nth($trans, 1);
      $vendor-prop-name: prefix($prop-name, $prefix);
      $prop-vals: nth($trans, 2);
      $prefixed: append($prefixed, $vendor-prop-name $prop-vals, comma);
  }
  @return $prefixed;
}

@mixin transitionCustom($values...) {
  $transitions: ();

  @each $declaration in $values {
      $prop: nth($declaration, 1);
      $prop-opts: ();
      $length: length($declaration);

      @if $length >= 2 {
          @for $i from 2 through $length {
              $prop-opts: append($prop-opts, nth($declaration, $i));
          }
      }
      $trans: $prop, $prop-opts;
      $transitions: append($transitions, $trans, comma);
  }
  -webkit-transition: trans-prefix($transitions, webkit);
  -moz-transition: trans-prefix($transitions, moz);
  -o-transition: trans-prefix($transitions, o);
  transition: $values;
}

// User select
// For selecting text on the page

@mixin user-select($select) {
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select; // IE10+
  user-select: $select;
}

// mouse grab

@mixin grab-cursor {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

@mixin grabbing-cursor {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}


.lg-css3 {
    &.lg-zoom-in {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(1.3, 1.3, 1.3);
            }

            &.lg-next-slide {
                @include scale3d(1.3, 1.3, 1.3);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-zoom-in-big {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-next-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-zoom-out {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(0.7, 0.7, 0.7);
            }

            &.lg-next-slide {
                @include scale3d(0.7, 0.7, 0.7);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }
    &.lg-zoom-out-big {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(0, 0, 0);
            }

            &.lg-next-slide {
                @include scale3d(0, 0, 0);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-zoom-out-in {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(0, 0, 0);
            }

            &.lg-next-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-zoom-in-out {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(2, 2, 2);
            }

            &.lg-next-slide {
                @include scale3d(0, 0, 0);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-soft-zoom {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include scale3d(1.1, 1.1, 1.1);
            }

            &.lg-next-slide {
                @include scale3d(0.9, 0.9, 0.9);
            }

            &.lg-current {
                @include scale3d(1, 1, 1);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-scale-up {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0.8, 0.8, 0.8) translate3d(0%, 10%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-slide-circular {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, 0, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, 0, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-up {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, -100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-down {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, 100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-slide-circular-vertical {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(0, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(0, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-vertical-left {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(-100%, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    // sec
    &.lg-slide-circular-vertical-down {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, -100%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0, 0, 0) translate3d(100%, 100%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 1s ease 0s);
            }
        }
    }

    &.lg-slide-vertical {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include translate3d(0, -100%, 0);
            }

            &.lg-next-slide {
                @include translate3d(0, 100%, 0);
            }

            &.lg-current {
                @include translate3d(0, 0, 0);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-vertical-growth {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(0.5, 0.5, 0.5) translate3d(0, -150%, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(0.5, 0.5, 0.5) translate3d(0, 150%, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(10deg, 0deg));
            }

            &.lg-next-slide {
                @include transform(skew(10deg, 0deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(-10deg, 0deg));
            }

            &.lg-next-slide {
                @include transform(skew(-10deg, 0deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only-y {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, 10deg));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, 10deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-only-y-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, -10deg));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, -10deg));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(20deg, 0deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(20deg, 0deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(-20deg, 0deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(-20deg, 0deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-cross {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, 60deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, 60deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-cross-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, -60deg) translate3d(-100%, 0%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, -60deg) translate3d(100%, 0%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(60deg, 0deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(60deg, 0deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(-60deg, 0deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(-60deg, 0deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver-cross {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, 20deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, 20deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-slide-skew-ver-cross-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(skew(0deg, -20deg) translate3d(0, -100%, 0px));
            }

            &.lg-next-slide {
                @include transform(skew(0deg, -20deg) translate3d(0, 100%, 0px));
            }

            &.lg-current {
                @include transform(skew(0deg, 0deg) translate3d(0%, 0%, 0px));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-lollipop {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include translate3d(-100%, 0, 0);
            }

            &.lg-next-slide {
                @include transform(translate3d(0, 0, 0) scale(0.5));
            }

            &.lg-current {
                @include translate3d(0, 0, 0);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-lollipop-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(translate3d(0, 0, 0) scale(0.5));
            }

            &.lg-next-slide {
                @include translate3d(100%, 0, 0);
            }

            &.lg-current {
                @include translate3d(0, 0, 0);
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-rotate {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(rotate(-360deg));
            }

            &.lg-next-slide {
                @include transform(rotate(360deg));
            }

            &.lg-current {
                @include transform(rotate(0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-rotate-rev {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(rotate(360deg));
            }

            &.lg-next-slide {
                @include transform(rotate(-360deg));
            }

            &.lg-current {
                @include transform(rotate(0deg));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }

    &.lg-tube {
        .lg-item {
            opacity: 0;

            &.lg-prev-slide {
                @include transform(scale3d(1, 0, 1) translate3d(-100%, 0, 0));
            }

            &.lg-next-slide {
                @include transform(scale3d(1, 0, 1) translate3d(100%, 0, 0));
            }

            &.lg-current {
                @include transform(scale3d(1, 1, 1) translate3d(0, 0, 0));
                opacity: 1;
            }

            &.lg-prev-slide, &.lg-next-slide, &.lg-current {
                @include transitionCustom(transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s);
            }
        }
    }
}

.block-special {
    &__slider {
        margin-bottom: 40px;

        @media (max-width: 768px) {
          margin-bottom: 30px;
        }
    }

    &__item {
      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
    }

    &__link {
        position: relative;
        display: flex;

        @media (max-width: 768px) {
          display: block;
        }
    }

    &__text {
        font-size: 20px;
        color: rgba(#fff, 0.9);
        padding: 10px;
        background: rgba(#000, 0.7);
        position: absolute;
        bottom: 0;
        left: 0;

        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
          padding: 6px 2px;
          opacity: 0.9;
        }
    }

    &__img {
      @media (max-width: 768px) {

      }
    }

    & .elem-title {
      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
    }
}

.slick-dots {
    display: flex;
    justify-content: center;
    margin-top: 18px;

    button {
        cursor: pointer;
        background: rgba(#fff, 0.5);
        border: none;
        border-radius: 50%;
        content: none;
        text-indent: -9999px;
        width: 10px;
        height: 10px;
        padding: 5px;
        &:hover {
            background: #fff;
        }
        &:focus {
            outline: none;
        }
    }

    li {
        width: 10px;
        height: 10px;
    }

    li+li {
        margin-left: 20px;
    }

    .slick-active {
        button {
            background: #fff;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            padding: 5px;
        }
    }
}

.block-service {
    padding-top: 36px;

    @media (max-width: 768px) {
      padding-top: 16px;
    }

    &__brands {
        display: flex;
        align-items: center;
        margin-left: 9px;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-top: 12px;
          padding: 0 4px;
          justify-content: space-between;
        }

        &-img {
            padding: 0 20px;

            @media (max-width: 768px) {
              padding: 0;
              min-width: 16% - 10px;
              text-align: center;
            }

            &:first-child {
              @media (max-width: 768px) {
                text-align: left;
              }
            }

            &:last-child {
              @media (max-width: 768px) {
                text-align: right;
              }
            }

            & + & {
                margin-left: 16px;
            }

            & img {
              @media (max-width: 768px) {
                max-height: 23px;
              }
            }
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 768px) {
          display: block;
          margin-bottom: 20px;
        }
    }

    &__item {
        margin-bottom: 40px;
        width: 100%/3 - 23px;

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 10px;
        }
    }

    &__link {
        color: #fff;
        // font-family: 'CorporateS';
        // font-weight: 300;
        font-size: 18px;
        opacity: 0.9;
    }
    &__text {
        padding: 10px 0;

        @media (max-width: 768px) {
          padding: 9px 0;
        }
    }
    &__img {
        max-width: 540px;

        @media (max-width: 768px) {
          max-width: 100%;
          max-height: 100px;
          display: flex;
          align-items: center;
          overflow: hidden;
        }

        img {
            width: 100%;
        }
    }

    .elem-title {
        margin-bottom: 0;

        @media (max-width: 768px) {
          margin-bottom: 11px;
        }
    }

    .slick-dots {
        position: absolute;
        bottom: 27px;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%, 0);
    }

    &__brand {
      @media (max-width: 768px) {
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        @media (max-width: 768px) {
          width: 100% / 4;
          text-align: center;
          padding: 0 10px;
          margin-bottom: 20px;
        }

        & img {
          @media (max-width: 768px) {
            max-height: 30px;
          }
        }
      }
    }
}

.elem-table {
  max-width: 1080px;
  margin: 0 auto;
  width: 100%;
  border-top: 1px solid #b3b6bb;
  border-bottom: 1px solid #b3b6bb;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 76px;

  @media (max-width: 768px) {
    display: block;
    border-top: 0;
    margin-bottom: 30px;
  }

  & thead {
    @media (max-width: 768px) {
      display: none;
    }
  }

  & th {
    font-weight: 400;
    color: rgba(#000000, 0.5);
    padding: 8px 10px;
    text-align: left;

    &:first-child {
      padding-left: 38px;
      width: 36%;
    }
  }

  & tbody {
    @media (max-width: 768px) {
      display: block;
    }

    & tr {
      @media (max-width: 768px) {
        display: block;
        padding-bottom: 15px;
        border-top: 1px solid #b3b6bb;
        padding-top: 15px;
      }
    }

    & td {
      border-top: 1px solid #b3b6bb;
      padding: 9px 10px 10px;
      color: #000000;

      &:first-child {
        padding-left: 38px;
        width: 36%;

        @media (max-width: 768px) {
          width: 100%;
          padding-left: 0;
        }
      }

      @media (max-width: 768px) {
        display: flex;
        border-top: 0;
        width: 100%;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
      }

      &:before {
        @media (max-width: 768px) {
          content: attr(data-th);
          width: 50%;
          flex-shrink: 0;
          padding-right: 15px;
          color: rgba(#000000, 0.5);
        }
      }
    }
  }
}

.block-contacts {
    background: #fff;
    padding: 57px 92px;
    color: #000;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        padding: 20px;
    }
    &__wrapper {
        display: flex;
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    &__info {
        display: flex;
        flex-basis: 450px;
        flex-direction: column;
        margin-left: auto;
        @media (max-width: 768px) {
            flex-basis: auto;
            margin-bottom: 20px;
        }
        p {
            font-size: 18px;
            @media (max-width: 768px) {
                margin: 9px 0;
            }
        }
        a {
            color: #7b7b7b;
            text-decoration: underline;
        }
    }
    &__map {
        margin-left: 55px;
        width: 806px;
        @media (max-width: 768px) {
            margin-left: 0;
            width: 100%;
        }
    }
    &__phone {
        font-size: 24px;
    }
}
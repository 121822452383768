.block-call {
    background: url('/assets/images/example/bg.jpg') no-repeat;
    background-size: cover;
    max-height: 200px;
    padding: 44px 0;
    @media (max-width: 768px) {
        max-height: none;
        padding: 14px 0;
        background-position: top;
        background-size: cover;
    }
    &__form {
        max-width: 1136px;
        margin: 0 auto;
        @media (max-width: 768px) {
            max-width: 100%;
        }
    }
    &__wrapper {
        max-width: 1162px;
        margin: 0 auto;
    }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 17px;
        @media (max-width: 768px) {
            display: block;
            margin-bottom: 8px;
        }
    }
    &__text {
        font-size: 19px;
        white-space: nowrap;
        min-width: 100px;
        @media (max-width: 768px) {
            font-size: 16px;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 9px;
        }
        & span {
            width: 100px;
            flex-shrink: 0;
            @media (max-width: 768px) {
                width: 84px;
            }
        }
        input {
            max-width: 329px;
            padding: 8px 10px 9px;
            margin-left: 15px;
            border: 0;
            @media (max-width: 768px) {
                width: 100%;
                max-width: 100%;
            }
        }
        &+& {
            margin-left: 62px;
            @media (max-width: 768px) {
                margin-left: 0;
            }
        }
    }
    &__btn {
        background: rgba(#000000, 0.5);
        color: #fff;
        border: 1px solid #fff;
        width: 263px;
        margin-left: 38px;
        height: 42px;
        cursor: pointer;
        font-family: 'CorporateS';
        @media (max-width: 768px) {
            width: 100%;
            margin-left: 0;
            height: 36px;
            font-size: 18px;
        }
    }
    &__checkbox {
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            padding-left: 99px;
        }
        input {
            border: 0;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        .block-call__check {
            cursor: pointer;
            position: relative;
            padding: 0 0 0px 29px;
            margin-bottom: 1rem;
            user-select: none;
            color: rgba(#fff, 0.5);
            font-size: 16px;
            @media (max-width: 768px) {
                font-size: 14px;
                padding-left: 32px;
                margin-bottom: 14px;
            }
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 20px;
                height: 20px;
                background: #fff;
                text-align: center;
                transition: background 200ms ease-out;
            }
            &:after {
                background-color: transparent;
                content: "";
                display: block;
                position: absolute;
                left: 8px;
                top: 2px;
                width: 3px;
                height: 10px;
                opacity: 0;
                border-bottom: 3px solid #000;
                border-right: 3px solid #000;
                transform: rotate(45deg);
                transition: border-color 0.3s ease;
            }
        }
        input[type="checkbox"]:checked+label:before {
            content: "";
        }
        input[type="checkbox"]:checked+label:after {
            content: "";
            opacity: 1;
        }
    }
    .elem-title {
        margin-bottom: 0;
        @media (max-width: 768px) {
            margin-bottom: 13px;
        }
    }
    &.gray {
        background: #363636;
        padding: 30px 0;
        margin-bottom: 40px;
        .elem-title {
            margin-bottom: 15px;
        }
    }
    &__input {
        width: 100%;
        max-width: 351px;
        height: 42px;
    }
    &__input+&__text {
        margin-left: 30px;
    }
    &--full &__wrapper {
        max-width: 100%;
        padding-left: 51px;
        padding-right: 51px;
        @media (max-width: 768px) {
            padding: 0 20px;
        }
    }
    &--full &__form {
        max-width: 1160px;
        margin-left: 0;
        margin-right: auto;
    }
    &--full &__btn {
        width: 168px;
        flex-shrink: 0;
        margin-left: 18px;
    }
    &--full &__checkbox {
        padding-left: 93px;
        @media (max-width: 768px) {
            padding-left: 99px;
        }
    }
}
.block-staff {
    &__row {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    &__wrapper {
        color: #000;
        background: #fff;
        padding: 25px;
        margin-bottom: 40px;
    }
    &__item {
        flex-basis: 220px;
        margin-right: 60px;
        flex-shrink: 0;
        @media (max-width: 768px) {
            margin-bottom: 30px;
        }
        &+& {
            // margin-left: 60px;
        }
    }
    &__img {
        margin-bottom: 20px;
    }
    &__name {
        font-size: 19px;
        margin-bottom: 5px;
    }
    &__position {
        font-size: 19px;
        line-height: 28px;
    }
    &__phone {
        font-size: 19px;
        line-height: 28px;
    }
    &__mail {
        color: #878787;
        font-size: 19px;
        line-height: 28px;
    }
}
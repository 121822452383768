.header {
    &-top {
        padding: 24px 0 18px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 768px) {
          padding: 14px 0 19px;
          display: block;
        }
    }
    &-logo {
        margin-left: 13px;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-bottom: 4px;
          display: flex;
        }

        &__img {
            width: 100%;
            img {
                width: 235px;

                @media (max-width: 768px) {
                  width: 168px;
                }
            }
        }
    }
    &-adress {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: flex-end;
        font-size: 18px;

        @media (max-width: 768px) {
          padding-left: 27px;
          font-size: 16px;
          align-items: flex-start;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -20px;
            background-image: url("/assets/images/example/adres.svg");
            background-repeat: no-repeat;
            width: 13px;
            height: 18px;
            background-size: contain;

            @media (max-width: 768px) {
              left: 4px;
              width: 10px;
              height: 14px;
              top: 3px;
            }
        }

        span {
            font-size: 14px;
            opacity: 0.5;
            margin-top: 10px;

            @media (max-width: 768px) {
              display: none;
            }
        }
    }

    &-phones {
        display: flex;
        flex-direction: column;

        @media (max-width: 768px) {
          flex-direction: row;
          align-items: center;
        }
    }

    &-phone {
        position: relative;
        font-size: 19px;
        opacity: 0.8;
        margin-bottom: 10px;
        font-family: 'CorporateS';
        font-weight: 700;

        @media (max-width: 768px) {
          padding-left: 24px;
          font-size: 15px;
          margin-bottom: 0;
        }

        &:before {
            content: '';
            position: absolute;
            top: 4px;
            left: -24px;
            background-image: url("/assets/images/example/tel.png");
            background-repeat: no-repeat;
            width: 16px;
            height: 16px;
            background-size: contain;

            @media (max-width: 768px) {
              left: 4px;
              top: 3px;
              height: 14px;
              width: 14px;
            }
        }
    }

    &-wa {
        font-size: 17px;
        opacity: 0.8;
        position: relative;
        color: #fff;
        text-decoration: none;
        padding-left: 10px;
        display: inline-block;

        @media (max-width: 768px) {
          margin-left: 28px;
          font-size: 17px;
          padding-left: 19px;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: -20px;
            background-image: url("/assets/images/example/wa.png");
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            background-size: contain;

            @media (max-width: 768px) {
              height: 14px;
              width: 14px;
              left: 0;
              top: 3px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: -5px;
            left: 10px;
            border-bottom: 1px dashed #fff;
            width: 72px;
            height: 1px;

            @media (max-width: 768px) {
              width: 70px;
              bottom: -4px;
              left: 20px;
            }
        }
    }
    &-info {
        margin-left: auto;
        margin-right: 212px;

        @media (max-width: 768px) {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 8px;
        }
    }
}

.form-question {
    background: #363636;
    @media (max-width: 768px) {
        margin-bottom: 36px;
    }
    &__form {
        max-width: 1183px;
        margin: 0 auto;
        padding: 50px 0;
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        .block-call__row {
            @media (max-width: 768px) {
                width: 100%;
            }
            input {
                max-width: 100%;
                width: 100%;
                margin-right: 15px;
                @media (max-width: 768px) {
                    margin-left: 0;
                    margin-right: 0;
                    margin-bottom: 8px;
                }
                &:last-child {
                    margin-right: 0;
                }
            }
            input[type=textarea] {
                height: 92px;
            }
        }
        .block-call__text {
            &+& {
                margin-left: 10px;
            }
        }
        .block-call__checkbox {
            @media (max-width: 768px) {
                padding-left: 0;
            }
        }
    }
}
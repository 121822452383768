/* Fonts generator */
@mixin font-face(
  $font-name,
  $font-path,
  $font-weight: 400,
  $font-style: normal
) {
  @font-face {
    font-family: $font-name;
    font-style: $font-style;
    font-weight: $font-weight;
    src: url('#{$font-path}.woff2') format('woff2'),
      url('#{$font-path}.woff') format('woff');
  }
}

/* Inline-block */
@mixin inlineblock($value: top) {
  display: inline-block;
  vertical-align: $value;
}

/* Absolute centered */
@mixin center {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

/* Clearfix */
@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

/* Color placeholder */
@mixin placeholder(
  $text-indent,
  $transition: 0.2s,
  $font: 14px,
  $color: #c7c6c6
) {
  &::-webkit-input-placeholder {
    @include transition($transition);
    color: $color;
    font-size: $font;
    text-indent: $text-indent;
  }

  &:-moz-placeholder {
    @include transition($transition);
    color: $color;
    font-size: $font;
    text-indent: $text-indent;
  }

  &::-moz-placeholder {
    @include transition($transition);
    color: $color;
    font-size: $font;
    text-indent: $text-indent;
  }

  &:-ms-input-placeholder {
    @include transition($transition);
    color: $color;
    font-size: $font;
    text-indent: $text-indent;
  }
}

@mixin transformPos($axis: 'both') {
  position: absolute;

  @if $axis== 'y' {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  @if $axis== 'x' {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  @if $axis== 'both' {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

/* Media queries max-width */
@mixin mediaMax($max-width: 1200px) {
  @media (max-width: $max-width) {
    @content;
  }
}

/* Media queries min-width */
@mixin media($min-width: 1200px) {
  @media (min-width: $min-width) {
    @content;
  }
}

/* Media queries mix */
@mixin mediaMix($min-width: 768px, $max-width: 1200px) {
  @media (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

/* Media queries for Retina */
@mixin retina {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

.elem-burger {
  width: 39px;
	height: 39px;
	position:relative;
	display:block;
	margin: 0;
	padding: 0;
	border: 0;
  outline: none;
  margin-left: auto;
  flex-shrink: 0;
  top: -3px;

	&__line {
		&:before,
		&:after {
			content: '';
		}

		top: 50% - 3px / 2;

		&:before {
			top: -9px;
		}

		&:after {
			top: 9px;
		}
	}

	&__line,
	&__line:before,
	&__line:after {
		height: 3px;
		width: 33px;
		display: block;
		background: #ffffff;
		position: absolute;
		left: 50%;
		border-radius: 0;
		transition: .3s ease-in-out;
		transform: translateX(-50%);
	}

	&__input {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
    z-index: 2;
    margin: 0;
	}

	&__input:checked + &__line:before {
		transform: translateY(9px) translateX(30px) rotate(135deg);
	}

	&__input:checked + &__line:after {
		transform: translateY(-9px) translateX(30px) rotate(-135deg);
	}

	&__input:checked + &__line {
    background-color: transparent;
    transform: translateX(-60px);
  }
}

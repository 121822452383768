.skill {
  height: 166px;
  position: relative;
  width: 166px;
}

.skill__circle {
  fill: transparent;

  &--under {
    stroke: #eaeaea;
    stroke-width: 5px;
  }

  &--above {
    stroke-dasharray: 251;
    stroke-dashoffset: 100;
    stroke-width: 12px;
  }

  &-text {
    color: rgba($text-color, 0.5);
    font-size: 18px;
    font-weight: bold;
    left: 50%;
    padding: 14px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
